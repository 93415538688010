import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsByd'


export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      position: [0, 0],
      size: [4, 4]
    }),

  ],
} as DasboardConfig
