import RestApiBuilder, { REST_API_ENDPOINTS } from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'

/**
 * Expose all endponts for 'critical moments'.
 */
export default <AlertWorkGroup>(api: ApisauceInstance): REST_API_ENDPOINTS<AlertWorkGroup> => {
  return {
    ...RestApiBuilder<AlertWorkGroup>('alert-work-groups', api)
  }
}