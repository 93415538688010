import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'


export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const CJ_IAMSA_ORDER = {
  'Compra': 1,
  'Abordaje': 2,
  'Autobús-Comodidad': 3,
  'Autobús-Funcionalidad': 4,
  'Autobús-Limpieza': 5,
  'Conductor': 6,
  'Arribo': 7
}

export const iamsaMcgExperienceMapper = (data: any) => {
  //orden solicitado por el cliente
  const order = {
    'Compra': 0,
    'Abordaje': 1,
    'Conductor': 2,
    'Autobús-Comodidad': 3,
    'Autobús-Limpieza': 4,
    'Autobús-Funcionalidad': 5,
    'Arribo': 6,
  }

  const dataCopy = [...data]
  dataCopy.sort((a: any, b: any) => b.value - a.value)

  const result: any[] = []

  dataCopy.forEach((item) => {
    result.push(item)
  })

  return result

}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
}
