import { filterGeos } from '@utils/GeoUtils'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { getCommonWidget } from '../dashWidgetHelper'
import Color from 'color'


const NEGATIVE_1_RED = '#88DD76'
const NEGATIVE_2_RED = '#AEDD76'
const NEUTRAL_ORANGE = '#F3AF5A'
const POSITIVE_2_GREEN = '#DB7476'
const POSITIVE_1_GREEN = '#CD4245'

export const PRODUTURO_SCALE_4_COLORS = [
  NEGATIVE_1_RED,
  NEGATIVE_2_RED,
  POSITIVE_2_GREEN,
  POSITIVE_1_GREEN,
]

export const PRODUTURO_SCALE_5_COLORS = [
  NEGATIVE_1_RED,
  NEGATIVE_2_RED,
  NEUTRAL_ORANGE,
  //'#34C0B8',
  POSITIVE_2_GREEN,
  POSITIVE_1_GREEN,
]

export const PROFUTURO_SACLE_5_COLORS_EXTENDS_RED = [
  ...PRODUTURO_SCALE_5_COLORS,
  '#b53032',
  '#a12b2d',
  '#8d2527',
  '#792022',
  '#651b1c',
  '#511516',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011'
]

export const PRODUTURO_SCALE_3_COLORS = [
  NEGATIVE_1_RED,
  NEUTRAL_ORANGE,
  POSITIVE_1_GREEN,
]

export const PRODUTURO_PIE_SINO_COLORS = [
  NEGATIVE_1_RED,
  POSITIVE_1_GREEN,
  NEUTRAL_ORANGE,
]

export const PRODUTURO_NPS_COLORS = [
  NEGATIVE_1_RED,
  POSITIVE_1_GREEN,
  NEUTRAL_ORANGE,
]

export const PRODUTURO_SENTIMENT_ANAL_COLORS = [
  NEGATIVE_1_RED,
  POSITIVE_1_GREEN,
  NEUTRAL_ORANGE,
]

export const PRODUTURO_PIE_COLORS = [
  //NEGATIVE_1_RED,
  '#34C0B8',
  '#FFD01B',
  '#F5B49F',
  '#B29BC2',
  '#777933',
  '#A668A8',
  '#9A9CCD',
  '#9C6D76',
  '#C0CB0B',
  '#878972',
  '#0C84AD',
  '#C357F2',
  '#C357F2',
  '#986CAF',
]

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  npsMultipleTrend: {
    title: 'TODO',
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'nps-multiple-trend',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: false,
      categories: ['promoters', 'passives', 'detractors'],
      colors: PRODUTURO_SCALE_3_COLORS,
      decimals: 2,
      useCurrent: 0,
      mapper: (data: any): any => {
        return data.map((item: any, idx: number) => ({ ...item, id: idx }))
      },
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'formattedMonth',
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'count',
          ],
          value: 'value',
          singleton: true,
          extras: {
            periods: 6
          }
        }
      ]
    }
  },
  npsMultipleTrendFourMonthPeriod: {
    title: 'TODO',
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'nps-multiple-trend-four-Month-Period',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: false,
      categories: ['promoters', 'passives', 'detractors'],
      colors: PRODUTURO_SCALE_3_COLORS,
      decimals: 2,
      useCurrent: 0,
      mapper: (data: any): any => {
        return data.map((item: any, idx: number) => {
          return { ...item, groupName: item.group, id: idx };
        })
      },
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'responseValue',
          label: 'group',
          value: 'value',
          groupByResponseValueId: 173003100,
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'count',
            'groupId',
            'group'

          ],
          singleton: true
        }
      ]
    }
  },
  genericSingleTrend: {
    title: 'TODO',
    // [Column, Row]
    position: [1, 1],
    size: [1, 2],
    config: {
      id: 'generic-single-trend',
      type: 'singleTrend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      decimals: 2,
      indicators: [
        {
          indicator: 'TODO',
          grouped: 'formattedMonth',
          keyExtract: ['value', 'groupName', 'count'],
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            periods: 6,
            //model: 'avg'
          },
        },
      ],
    },
  },
  textAnalysis: {
    ...widgetsLibrary.textAnalysis,
    //@ts-ignore
    config: {
      ...widgetsLibrary.textAnalysis.config,
      //colors: ['#A8EB12', '#D95A26', '#00B287'],
      colors: PRODUTURO_SCALE_3_COLORS
        .map((color: string) => Color(color).alpha(0.5).toString()),
      groupKeys: ['positive', 'neutral', 'negative'],
      mapper: (data: any): any => {
        const newData = data !== undefined
          ? {
            ...data,
            categories: data.categories
              /*.filter((cat: any) => cat.group !== 'DEFAULT')
              .map((cat: any) => ({
                ...cat,
                tags: cat.tags.filter((cat: any) => cat.group !== 'DEFAULT')
              }))
              */

          }
          : {}
        return newData
      },
    }
  },
  wordCount: {
    title: 'FALTA texto',
    position: [4, 2],
    size: [2, 2],
    config: {
      id: 'word-count',
      type: 'wordCloud',
      mapper: (data: any): any => {
        return data.filter((item: any) => item.group !== 'DEFAULT')
      },
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['count', 'value', 'group'],
          singleton: true,
          extras: {
            formula: 'word-count'
          }
        }
      ]
    }
  },

  countNumberSemicircle: {
    ...widgetsLibrary.countNumberSemicircle,
    //@ts-ignore
    config: {
      ...widgetsLibrary.countNumberSemicircle.config,
      showValue: true,
      valuePosition: 'inner',
      center: ['50%', '46%'],
      radius: ['40%', '90%'],
      decimals: 0,
      itemHeight: 10,
      padAngle: 10,
      colors: PRODUTURO_SCALE_5_COLORS,
      itemBorderRadius: 5,
      mapper: (data: any): any => {
        const sortedValues = data?.details?.asMutable()
          // @ts-ignore
          .sort((c1, c2) => c2.groupId > c1.groupId)
          .map((item: any) => item.value)

        return {
          ...data,
          value: sortedValues !== undefined
            && sortedValues.length > 0
            ? sortedValues[0]
            : data.value
        }
      },
    }
  },

  semicircleGeneric: {
    ...widgetsLibrary.semicircleGeneric,
    //@ts-ignore
    config: {
      ...widgetsLibrary.semicircleGeneric.config,
      colors: PRODUTURO_SCALE_3_COLORS,
      itemBorderRadius: 5,
      showValue: true,
      valuePosition: 'inner',
      decimals: 0,
    }
  },

  stackedBar: {
    ...widgetsLibrary.stackedBar,
    //@ts-ignore
    config: {
      ...widgetsLibrary.stackedBar.config,
      colors: PRODUTURO_SCALE_5_COLORS,
      decimals: 0,
    }
  },
  pieGenericMedium: {
    ...widgetsLibrary.pieGenericMedium,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericMedium.config,
      decimals: 0,
    }
  },
  pieGenericLarge: {
    ...widgetsLibrary.pieGenericLarge,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericLarge.config,
      decimals: 0,
    }
  },
  pieGenericMediumSiNo: {
    ...widgetsLibrary.pieGenericMedium,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericMedium.config,
      colors: PRODUTURO_PIE_SINO_COLORS,
      decimals: 0,
    }
  },
  multipleValueStackedBar: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-question',
      type: 'stackedBar',
      colors: PROFUTURO_SACLE_5_COLORS_EXTENDS_RED,
      percentageScale: false,
      showValues: true,
      mapper: (data: any): any => {

        let groupedValues: { [key: number]: { count: number, group: number, value: number } } = {};
        let acumCount = 0;

        data
          .filter((item: any) => {
            let groupNum = parseInt(item.group);
            return !isNaN(groupNum) && groupNum > 0;// && groupNum <= 20;
          })
          .forEach((item: any) => {
            let groupNum = parseInt(item.group);
            if (!groupedValues[groupNum]) {
              groupedValues[groupNum] = { count: 0, group: groupNum, value: 0 };
            }
            groupedValues[groupNum].count += item.count;
            acumCount += item.count;
          });

        return Object.values(groupedValues)
          .map((item: any) => {
            item.value = acumCount > 0 ? Math.round((item.count * 100) / acumCount) : 0;
            return item;
          })
          .sort((c1: any, c2: any) => c1.group - c2.group);
      },
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'count'],
          label: 'textAnswer',
          grouped: 'textAnswer',
          value: 'value',
          singleton: true
        },
      ],
    },
  },

}

export const getProfuturoNpsPorMes = (props: {
  indicator: string,
  title?: string,
  position: number[],
  size: number[],
  helpText?: string
  extras?: { [key: string]: any },
  extraConfigs?: { [key: string]: any },
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any }

}) => {
  const { 
    indicator,
    title, 
    position, 
    size,
    helpText = '¿qué tan probable es que recomiendes a Profuturo a tus amigos y familiares?',
    extras,
    extraConfigs,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras

  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: title ?? 'NPS Acumulado',
    key: 'nps-por-mes',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    extraConfigs: extraConfigs ? extraConfigs : {
      //colors: PRODUTURO_NPS_COLORS,
      categories: ['promoters', 'passives', 'detractors'],
      useEmojis: false
    },
    historic: {
      type: 'line',
      formula: 'nps-10',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras

    }
  })
}

export const getProfuturoSatisfaccion = (props: {
  indicator: string,
  position: number[],
  size: number[],
  helpText: string,
  extras?: { [key: string]: any }
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any }
}) => {
  const {
    indicator,
    position,
    size,
    helpText,
    extras,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Satisfacción',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras
    }
  })
}



export const getProfuturoAtencion = (props: {
  indicator: string,
  position: number[],
  size: number[],
  helpText: string
}) => {
  const { indicator, position, size, helpText } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Atención',
    position: position,
    size: size,
    helpText: helpText,
    indicator: indicator,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top'
    }
  })
}

export const getProfuturoAsesoria = (props: {
  indicator: string,
  position: number[],
  size: number[],
  helpText: string
}) => {
  const { indicator, position, size, helpText } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Asesoria',
    position: position,
    size: size,
    helpText: helpText,
    indicator: indicator,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top'
    }
  })
}

export const getProfuturoFacilidad = (props: {
  indicator: string,
  position: number[],
  size: number[],
  title?: string,
  helpText: string,
  extras?: { [key: string]: any }
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any }

}) => {
  const {
    indicator,
    position,
    size,
    title = 'Facilidad',
    helpText,
    extras,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: title,
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras,
    }
  })
}

export const getProfuturoNpsAcumulado = (props: {
  indicator: string,
  position: number[],
  size: number[],
}) => {
  const { indicator, position, size } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'npsMultipleTrend',
    title: 'NPS por mes',
    key: 'nps-acumulado',
    position: position,
    size: size,
    indicator: indicator,
    extraConfigs: {
      decimals: 0
    }
  })
}

export const getProfuturoNpsAcumuladoFourMonth = (props: {
  indicator: string,
  position: number[],
  size: number[],
  extras?: { [key: string]: any },
}) => {
  const { indicator, position, size, extras } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'npsMultipleTrendFourMonthPeriod',
    title: 'NPS por Cuatrimestre',
    key: 'nps-acumulado-cuatrimestre',
    position: position,
    size: size,
    indicator: indicator,
    extras: {
      ...extras
    },
    extraConfigs: {
      decimals: 0
    }
  })
}

export const getProfuturoNpsConsar = (props: {
  indicator: string,
  position: number[],
  size: number[],
  title?: string,
  helpText: string,
  extraConfigs?: { [key: string]: any }
}) => {
  const {
    indicator,
    position,
    size,
    title,
    helpText,
    extraConfigs
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: title,
    key: 'nps-por-mes',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extraConfigs: extraConfigs ? extraConfigs : {
      //colors: PRODUTURO_PIE_SINO_COLORS,
      categories: ['promoters', 'passives', 'detractors'],
      useEmojis: false
    },
    historic: {
      type: 'line',
      formula: 'nps-10'
    }
  })
}


