import { ActionCreatorsMapObject } from 'redux'
import { createModule, STATUS, EffectStatus, EffectParam } from 'redux-acron'
import { call, put } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { REHYDRATE } from 'redux-persist'
import Immutable from 'seamless-immutable'
import api, { vocApi, lcmApi, caseApi } from '@services/api'

export type AuthState = {
  token: string | null
  loading: boolean
  error: boolean
  errorPayload: any
}

type StoreData = {
  payload: {
    appData: Object
    auth?: AuthState
  }
}

const AuthModule = createModule<AuthState>({
  name: 'auth',
  state: {
    token: null,
    loading: false,
    error: false,
    errorPayload: null
  },
  handlers: {
    signin: (
      state,
      {
        payload,
        status = STATUS.PENDING_STATUS,
      }: { payload: { token: string }; status: EffectStatus }
    ) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          loading: true,
          error: null,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loading: false,
          error: null,
          token: payload.token,
        })
      }

      if (status === STATUS.FAILURE_STATUS) {
        return state.merge({
          loading: false,
          error: true,
          token: null,
          errorPayload: payload
        })
      }

      return state
    },

    signout: (state) => {
      return state.merge({
        loading: false,
        error: null,
        token: null,
        errorPayload: null
      })
      return state
    },

    [REHYDRATE]: (state, { payload }: StoreData) => {
      if (payload && payload.auth && payload.auth.token) {
        vocApi.setHeaders({ Authorization: `Bearer ${payload.auth.token}`, })
        lcmApi.setHeaders({ Authorization: `Bearer ${payload.auth.token}`, })
        caseApi.setHeaders({ Authorization: `Bearer ${payload.auth.token}`, })
      }

      if (payload && payload.auth) {
        return Immutable({
          ...payload.auth,
          loading: false,
          error: false,
        })
      }

      return state
    },

    setToken: (
      state,
      { payload }: { payload: { token: string }; status: EffectStatus }
    ) => {
      if (payload.token) {
        vocApi.setHeaders({ Authorization: `Bearer ${payload.token}`, })
        lcmApi.setHeaders({ Authorization: `Bearer ${payload.token}`, })
        caseApi.setHeaders({ Authorization: `Bearer ${payload.token}`, })
        return state.merge({
          token: payload.token,
        })
      }
      return state
    },
  },

  effects: {
    signin: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ username: string; password: string }>
      ) {
        const response: { ok: boolean; data: any } = yield call(
          api.auth.signin,
          payload.username,
          payload.password
        )

        if (response.ok) {
          vocApi.setHeaders({ Authorization: `Bearer ${response.data.id_token}`, })
          lcmApi.setHeaders({ Authorization: `Bearer ${response.data.id_token}`, })
          caseApi.setHeaders({ Authorization: `Bearer ${response.data.id_token}`, })

          const userInfoResponse: {
            data: UserAccount
            ok: boolean
          } = yield call(api.account.getInfo)

          if (userInfoResponse.ok) {
            const { data: userInfo } = userInfoResponse
            // If the user isn't activated
            if (!userInfo.enabled) {
              yield put(actions.signin({}, STATUS.FAILURE_STATUS))
              return
            }

            // If the use isn't an admin we check the profile access
            if (userInfo.authorities.indexOf('ROLE_ADMIN') === -1) {
              const defaultProfile = userInfo?.userProfiles?.[0]

              if (
                !defaultProfile ||
                !defaultProfile.config ||
                !defaultProfile.config.access
              ) {
                yield put(actions.signin({}, STATUS.FAILURE_STATUS))
                return
              }
            }

            yield put(
              actions.signin(
                {
                  token: response.data.id_token,
                  data: userInfo,
                },
                STATUS.SUCCESS_STATUS
              )
            )
          }
        } else {
          yield put(actions.signin(response.data, STATUS.FAILURE_STATUS))
        }
      },
    },

    signout: {
      *callback(actions: ActionCreatorsMapObject) {
        // // @ts-ignore
        // if (persistor) {
        //   // @ts-ignore
        //   yield call(persistor.purge)
        // }
        yield put(actions.signout({}, STATUS.SUCCESS_STATUS))
        yield put(replace('/login', {}))
      },
    },
  },
})

export default AuthModule
