import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const bupaStakedBarDetailedByMonthMapper = (data: any, extra: any) => {
  console.log('bupaBarDetailedByMonthMapper')
  console.log(data)

  const values = data.reduce((acc: any, curr: any) => {
    const groupName = curr?.groupName ?? curr?.['group_0']
    const category = curr?.label ?? curr?.['group_1']
    return {
      ...acc,
      [groupName]: {
        ...(acc[groupName] ?? {}),
        [category]: curr.value,
        group: groupName,
        groupName: groupName
      }
    };
  }, {})

  console.log(values)

  return data
};

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
}
