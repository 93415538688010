import useAccount from "@hooks/useAccount"

import { useTranslation } from "react-i18next"
import useClientConfig from "./useClientConfig"

interface ClientTranslationConfig {
  translate: (value: string, defaultValue?: string) => string
  translationReady: () => boolean
}


const useClientTranslation = (): ClientTranslationConfig => {

  const { getClientName } = useClientConfig()
  const commonFileName = 'common'
  const clientFileName = 'common-' + getClientName()
  const { t } = useTranslation([clientFileName, commonFileName])
  const { ready } = useTranslation([clientFileName, commonFileName], { useSuspense: false, })

  const translate = (value: string, defaultValue?: string): string => {
    //console.log('Translating ' + value)
    //console.log(commonFileName)
    //console.log(clientFileName)
    //console.log(t(value, { ns: commonFileName }))

    if (value !== undefined && value.trim().includes(' ')) {
      return value
    }

    if (t(value, { ns: clientFileName }) === value) {
      return t(value, { ns: commonFileName, defaultValue: defaultValue ?? value  })
    }

    return t(value, { ns: clientFileName, defaultValue: defaultValue ?? value })
  }

  const translationReady = (): boolean => {
    return ready
  }

  return {
    translate,
    translationReady,
  }
}

export default useClientTranslation
