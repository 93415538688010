import { BYD_CJ_ORDER } from 'config/clients/omoikane/omoikaneByd'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsByd'
import { COLORS_CONFIG_GAUGE_5 } from '@components/widgets/apacheEchart/Commons'
import useClientTranslation from 'config/clients/useClientTranslation'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'



export const bydTooltipTotalEncuestas = (data: any) => {
  const {translate} = useClientTranslation()
  return translate('surveys.totals') + ' ' + data.total //'Total encuestas ' + data.total
}

export const bydCustomerJourneyVentaMapper = (data: any, extra: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      label: 'widgets.indicatorId.' + item.groupId,
      weight: BYD_CJ_ORDER[item.groupId] ?? item.groupId
    }
  })
  // @ts-ignore
  .asMutable()
  .sort((item1: any, item2: any) => item1.weight - item2.weight )
}

export const bydCustomerJourneyServicioMapper = (data: any, extra: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      label: 'widgets.indicatorId.' + item.groupId,
      weight: BYD_CJ_ORDER[item.groupId] ?? item.groupId
    }
  })
  // @ts-ignore
  .asMutable()
  .sort((item1: any, item2: any) => item1.weight - item2.weight )
}

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'GENERAL_SATISFACCION',
      radialIndicators: [
        'VENTA_SATISFACCION',
        'VENTA_NPS',
        'survey-response-counters',
        'SERVICIO_SATISFACCION',
        'SERVICIO_NPS',
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 4],
      title: 'widgets.snByGeo',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        decimals: 1
      },
      //criticalMomentInclude: [1],
      groupByLevel: 2,
      indicatorsNames: {
        'VENTA_SATISFACCION': 'Satisfacción Venta',
        'VENTA_NPS': 'NPS Venta',
        'survey-response-counters': '% de Respuestas',
        'SERVICIO_SATISFACCION':  'Satisfacción Servicio',
        'SERVICIO_NPS': 'NPS Servicio',
      },
    }),
    {
      title: 'labels.venta',
      type: 'label',
      position: [0, 4],
      size: [4, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 5],
      size: [1, 2],
      criticalMomentId: 1,
      indicator: 'VENTA_SATISFACCION',
      extraConfigs: {
        decimals: 1
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsVenta',
      position: [1, 5],
      size: [1, 2],
      criticalMomentId: 1,
      indicator: 'VENTA_NPS',
      extraConfigs: {
        decimals: 0
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      indicator: 'venta-calidad-datos-gauge',
      url: 'survey-responses/values',
      criticalMomentId: 1,
      mapper: invalidMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      position: [2, 5],
      size: [1, 2],
      extraConfigs: {
        decimals: 1
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-porc-respuestas-gauge',
      url: 'survey-responses/values',
      criticalMomentId: 1,
      mapper: answeredMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      position: [3, 5],
      size: [1, 2],
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: bydCustomerJourneyVentaMapper,
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1,
      position: [0, 7],
      size: [4, 2],
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      },
    }),
    {
      title: 'labels.postventa',
      type: 'label',
      position: [0, 9],
      size: [4, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [0, 10],
      size: [1, 2],
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsPostventa',
      indicator: 'SERVICIO_NPS',
      criticalMomentId: 2,
      position: [1, 10],
      size: [1, 2],
      extraConfigs: {
        decimals: 0
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      indicator: 'postventa-calidad-datos-gauge',
      criticalMomentId: 2,
      position: [2, 10],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'postventa-porc-respuestas-gauge',
      url: 'survey-responses/values',
      criticalMomentId: 2,
      mapper: answeredMapper,
      position: [3, 10],
      size: [1, 2],
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      criticalMomentId: 2,
      mapper: bydCustomerJourneyServicioMapper,
      indicator: 'SERVICIO_CUSTOMER_JOURNEY',
      position: [0, 12],
      //position: [0, 4],
      size: [4, 2],
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      },
    }),
    {
      title: 'Venta',
      type: 'label',
      position: [0, 14],
      size: [2, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [0, 15],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [1, 15],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      }
    }),
    {
      title: 'Servicio',
      type: 'label',
      position: [2, 14],
      size: [2, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [2, 15],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [3, 15],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      }
    }),
    {
      title: 'Análisis de datos',
      type: 'label',
      position: [0, 17],
      size: [4, 1]
    },
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 14],
      size: [2, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [2, 14],
      size: [2, 2],
      indicator: 'VENTA_NPS',
    }),*/
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      position: [0, 18],
      size: [4, 3]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 21],
      size: [4, 2],
      indicator: 'SENTIMENT'
    }),
  ],
} as DasboardConfig
