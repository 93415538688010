import RestApiBuilder, { REST_API_ENDPOINTS } from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'

/**
 * Expose all endponts for 'critical moments'.
 */
export default <AlertWorkflow>(api: ApisauceInstance): REST_API_ENDPOINTS<AlertWorkflow> => {
  return {
    ...RestApiBuilder<AlertWorkflow>('alert-workflows', api)
  }
}